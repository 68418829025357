import React from "react";
import "./Question.css";

function Question(props) {
  const { ques, answer } = props;
  return (
    <>
      <div className="questionBorder">
        <div className="p-4 questionText">คำถาม : {ques}</div>
        <hr />
        <div className="p-4">คำตอบ : {answer}</div>
      </div>
    </>
  );
}

export default Question;
