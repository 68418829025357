import React from "react";
import "./MainTablet.css";
import logo from "./pic/logo.jpg";
import orderPic from "./pic/order.jpg";
import lineID from "./pic/lineG.jpg";
import truck01 from "./pic/truck.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Outlet, Link } from "react-router-dom";

import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function OrderT() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [lineId, setLine] = useState("");

  const saveInput = () => {
    if (name.length === 0) {
      toast.error("กรุณาใส่ชื่อ-นามสกุล", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }
    if (lineId.length === 0) {
      toast.error("กรุณาใส่ Line ID", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }
    setName("");
    setEmail("");
    setTel("");
    setLine("");
    toast.success("ส่งข้อมูลเรียบร้อยแล้ว", {
      position: "top-center",
      theme: "colored",
      autoClose: 2000,
    });
  };
  return (
    <>
      <div className="bg-white h-screen">
        <div className="menuWhite flex">
          <div className="pt-2 pl-2">
            <div className="dropdown">
              <button className="dropbtn">
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "#E5958E", fontSize: "24px" }}
                />
              </button>
              <div className="dropdown-content">
                <Link to="/">
                  <div>หน้าหลัก</div>
                </Link>
                <Link to="/order">
                  <div>การสั่งซื้อสินค้า</div>
                </Link>
                <Link to="/faq">
                  <div>FAQ</div>
                </Link>
                <Link to="/contactus">
                  <div>ติดต่อเรา</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <img src={logo} style={{ height: "40px" }} alt="best item logo" />
          </div>
        </div>

        <div>
          <img src={orderPic} alt="สั่งซื้อสินค้า" style={{ width: "100%" }} />
        </div>

        <div className="flex justify-center pt-4">
          <div className="pt-3">
            <b>สั่งซื้อสินค้า</b>
          </div>
          <div className="px-4">
            <img src={lineID} alt="ไลน์ไอดี" />
          </div>
          <div className="pt-3">
            <b>@bestitem</b>
          </div>
        </div>

        <div className="flex justify-center pt-4">
          <div>
            <img src={truck01} alt="รถบรรทุก" />
          </div>
          <div className="pl-3">
            ส่งฟรีเมื่อช้อปครบ 300 บาทขึ้นไป | จัดส่งภายใน 1 วัน{" "}
          </div>
        </div>

        <div className="pt-4 headerText">
          ลงทะเบียนเพื่อรับสิทธิพิเศษอีกมากมาย
        </div>

        <div className="flex inputMain pt-4">
          <div className="flex-1 pt-2">ชื่อ - นามสกุล (โปรดระบุ)</div>
          <div className="flex-1">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="inputText"
            />
          </div>
        </div>

        <div className="flex inputMain pt-4">
          <div className="flex-1 pt-2">อีเมล์ </div>
          <div className="flex-1">
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="inputText"
            />
          </div>
        </div>

        <div className="flex inputMain pt-4">
          <div className="flex-1 pt-2">เบอร์โทรศัพท์ </div>
          <div className="flex-1">
            <input
              type="text"
              value={tel}
              onChange={(e) => setTel(e.target.value)}
              className="inputText"
            />
          </div>
        </div>

        <div className="flex inputMain pt-4">
          <div className="flex-1 pt-2">Line ID (โปรดระบุ) </div>
          <div className="flex-1">
            <input
              type="text"
              value={lineId}
              onChange={(e) => setLine(e.target.value)}
              className="inputText"
            />
          </div>
        </div>

        <div className="flex inputMain pt-4 justify-center">
          <button type="button" className="btn" onClick={saveInput}>
            ส่งข้อมูล
          </button>
        </div>
        <div className="inputMain pt-4" style={{ fontSize: "12px" }}>
          ในการคลิก “ส่งข้อมูล” เท่ากับคุณได้ยอมรับให้ได้รับการติดต่อกลับจาก
          best item ที่มีวัตถุประสงค์เพื่อการแนะนำผลิตภัณฑ์
          บริการของเราตามที่ระบุไว้ใน เงื่อนไขการให้บริการ และ
          นโยบายความเป็นส่วนตัว โดยคุณสามารถยกเลิกการติดต่อเพื่อวัตถุประสงค์
          ทางการตลาดได้ตลอดเวลา โดยการติดต่อไปที่ฝ่ายบริการลูกค้า
          ของเราผ่านช่องทางที่ระบุไว้ ในนโยบายความเป็นส่วนตัว
        </div>
      </div>

      <Outlet />
      <ToastContainer />
    </>
  );
}

export default OrderT;
