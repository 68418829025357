import React from "react";

import "../../App.css";
import "./LeftMenu.css";
import bestItemLogo from "./logo.jpg";
import { Outlet, Link } from "react-router-dom";

function LeftMenu(props) {
  const { menu } = props;
  return (
    <>
      <div className="mainLeftMenu px-4 border-r-2">
        <div className="pt-4">
          <img
            src={bestItemLogo}
            alt="Best item logo"
            style={{ width: "230px", margin: "auto" }}
          />
        </div>
        <Link to="/">
          {menu === 1 && <div className="py-3 px-3 menuSelect">หน้าหลัก</div>}
          {menu !== 1 && <div className="py-3 px-3 ">หน้าหลัก</div>}
        </Link>
        <Link to="/order">
          {menu === 2 && (
            <div className="py-3 px-3 menuSelect">การสั่งซื้อสินค้า</div>
          )}
          {menu !== 2 && <div className="py-3 px-3 ">การสั่งซื้อสินค้า</div>}
        </Link>
        <Link to="/faq">
          {menu === 3 && <div className="py-3 px-3 menuSelect">FAQ</div>}
          {menu !== 3 && <div className="py-3 px-3 ">FAQ</div>}
        </Link>
        <Link to="/contactus">
          {menu === 4 && <div className="py-3 px-3 menuSelect">ติดต่อเรา</div>}
          {menu !== 4 && <div className="py-3 px-3 ">ติดต่อเรา</div>}
        </Link>

        <div
          className="fixed bottom-0 text-slate-500 pr-4"
          style={{ width: "250px" }}
        >
          <hr />
          <div className="fontSub py-4 underline cursor-pointer ">
            <Link to="/privacy">นโยบายเว็บไซด์และความเป็นส่วนตัว</Link>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default LeftMenu;
