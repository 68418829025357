import React from "react";
import LeftMenu from "./pc/LeftMenu";
import ContactusPC from "./pc/Contactus";
import ContactusT from "./tablet/Contactus";
import ContactusM from "./mobile/ContactM";

function Contactus() {
  const menu = 4;
  return (
    <>
      <div className="hidden lg:block mainPC ">
        <div className="flex ">
          <div className="w-200">
            <LeftMenu menu={menu} />
          </div>
          <div className="flex-1 bg-white">
            <ContactusPC />
          </div>
        </div>
      </div>
      <div className="hidden md:block lg:hidden">
        <ContactusT />
      </div>
      <div className=" block md:hidden">
        <ContactusM />
      </div>
    </>
  );
}

export default Contactus;
