import React from "react";
import "../App.css";
import LeftMenu from "./pc/LeftMenu";
import MainPC from "./pc/MainPC";
import MainTablet from "./tablet/MainTablet";
import MainMobile from "./mobile/MainMobile";

function MainApp(props) {
  const menu = 1;
  return (
    <>
      <div className="hidden lg:block mainPC ">
        <div className="flex ">
          <div className="w-200">
            <LeftMenu menu={menu} />
          </div>
          <div className="flex-1 bg-white">
            <MainPC />
          </div>
        </div>
      </div>
      <div className="hidden md:block lg:hidden">
        <MainTablet />
      </div>

      <div className=" block md:hidden">
        <MainMobile />
      </div>
    </>
  );
}

export default MainApp;
