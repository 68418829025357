import React from "react";
import banner from "./pic/order.jpg";
import line from "./pic/line.png";
import truck from "./pic/truck.png";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Order.css";
function OrderPC() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [lineId, setLine] = useState("");

  const saveInput = () => {
    if (name.length === 0) {
      toast.error("กรุณาใส่ชื่อ-นามสกุล", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }
    if (lineId.length === 0) {
      toast.error("กรุณาใส่ Line ID", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }
    setName("");
    setEmail("");
    setTel("");
    setLine("");
    toast.success("ส่งข้อมูลเรียบร้อยแล้ว", {
      position: "top-center",
      theme: "colored",
      autoClose: 2000,
    });
  };

  return (
    <>
      <div>
        <img src={banner} alt="การสั่งซื้อ" />
      </div>
      <div className="flex justify-center pt-4">
        <div className="pt-4 px-2">
          <b>สั่งซื้อได้ที่</b>{" "}
        </div>
        <div>
          <img src={line} alt="line สั่งซื้อสินค้า" />
        </div>
        <div className="pt-4 px-2">
          <b>@bestitem</b>
        </div>
      </div>
      <div className="flex justify-center pt-4">
        <div className="px-2 ">
          <img src={truck} alt="รถส่งของ" />
        </div>
        <div>ส่งฟรีเมื่อช้อปครบ 300 บาทขึ้นไป | จัดส่งภายใน 1 วัน </div>
      </div>
      <div className="headerText pt-4">
        ลงทะเบียนเพื่อรับสิทธิพิเศษอีกมากมาย
      </div>

      <div className="flex inputMain pt-4">
        <div className="flex-1 pt-2">ชื่อ - นามสกุล (โปรดระบุ)</div>
        <div className="flex-1">
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="inputText"
          />
        </div>
      </div>

      <div className="flex inputMain pt-4">
        <div className="flex-1 pt-2">อีเมล์ </div>
        <div className="flex-1">
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="inputText"
          />
        </div>
      </div>

      <div className="flex inputMain pt-4">
        <div className="flex-1 pt-2">เบอร์โทรศัพท์ </div>
        <div className="flex-1">
          <input
            type="text"
            value={tel}
            onChange={(e) => setTel(e.target.value)}
            className="inputText"
          />
        </div>
      </div>

      <div className="flex inputMain pt-4">
        <div className="flex-1 pt-2">Line ID (โปรดระบุ) </div>
        <div className="flex-1">
          <input
            type="text"
            value={lineId}
            onChange={(e) => setLine(e.target.value)}
            className="inputText"
          />
        </div>
      </div>

      <div className="flex inputMain pt-4 justify-center">
        <button type="button" className="btn" onClick={saveInput}>
          ส่งข้อมูล
        </button>
      </div>

      <div className="inputMain pt-4" style={{ fontSize: "12px" }}>
        ในการคลิก “ส่งข้อมูล” เท่ากับคุณได้ยอมรับให้ได้รับการติดต่อกลับจาก best
        item ที่มีวัตถุประสงค์เพื่อการแนะนำผลิตภัณฑ์ บริการของเราตามที่ระบุไว้ใน
        เงื่อนไขการให้บริการ และ นโยบายความเป็นส่วนตัว
        โดยคุณสามารถยกเลิกการติดต่อเพื่อวัตถุประสงค์ ทางการตลาดได้ตลอดเวลา
        โดยการติดต่อไปที่ฝ่ายบริการลูกค้า ของเราผ่านช่องทางที่ระบุไว้
        ในนโยบายความเป็นส่วนตัว
      </div>
      <ToastContainer />
    </>
  );
}

export default OrderPC;
