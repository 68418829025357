import React from "react";
import contactPic from "./pic/contactus.jpg";
import logo from "./pic/logo.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import email from "./pic/email.png";
import lineID from "./pic/linebw.png";
import tel from "./pic/tel.png";
import { Outlet, Link } from "react-router-dom";
function Contactus() {
  const [name, setName] = useState("");
  const [emailSend, setEmail] = useState("");
  const [telephone, setTel] = useState("");
  const [topic, setTopic] = useState("ปัญหาการใช้งาน");
  const [message, setMessage] = useState("");

  const saveInput = () => {
    if (name.length === 0) {
      toast.error("กรุณาใส่ชื่อ-นามสกุล", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }
    if (message.length === 0) {
      toast.error("กรุณาใส่ข้อความ", {
        position: "top-center",
        theme: "colored",
        autoClose: 2000,
      });
      return;
    }
    setName("");
    setEmail("");
    setTel("");
    setTopic("ปัญหาการใช้งาน");
    setMessage("");
    toast.success("ส่งข้อมูลเรียบร้อยแล้ว", {
      position: "top-center",
      theme: "colored",
      autoClose: 2000,
    });
  };

  const clearData = () => {
    setName("");
    setEmail("");
    setTel("");
    setTopic("ปัญหาการใช้งาน");
    setMessage("");
  };

  return (
    <>
      <div className="bg-white h-screen">
        <div className="menuWhite flex">
          <div className="pt-2 pl-2">
            <div className="dropdown">
              <button className="dropbtn">
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "#E5958E", fontSize: "24px" }}
                />
              </button>
              <div className="dropdown-content">
                <Link to="/">
                  <div>หน้าหลัก</div>
                </Link>
                <Link to="/order">
                  <div>การสั่งซื้อสินค้า</div>
                </Link>
                <Link to="/faq">
                  <div>FAQ</div>
                </Link>
                <Link to="/contactus">
                  <div>ติดต่อเรา</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <img src={logo} style={{ height: "40px" }} alt="best item logo" />
          </div>
        </div>
        <div>
          <img src={contactPic} alt="ติดต่อเรา" style={{ width: "100%" }} />
        </div>
        <div className="flex px-12">
          <div className="flex-1">
            <div className="pt-4">บริษัท Best Item จำกัด</div>
            <div>
              295 ซอย อรุณอัมรินทร์ แขวงบางยี่ขัน เขตบางพลัด
              <br /> กรุงเทพมหานคร 10700
            </div>
            <div className="pt-4 flex">
              <div className="pl-3">
                <img src={email} alt="อีเมล์" />
              </div>
              <div className="pl-3">อีเมล์ : support.bestitem@gmail.com</div>
            </div>
            <div className="pt-4 flex">
              <div className="pl-3">
                <img src={lineID} alt="ไลน์แอด" />
              </div>
              <div className="pl-3 pt-2">ไลน์แอด : @bestitem</div>
            </div>
            <div className="pt-4 flex">
              <div className="pl-3">
                <img src={tel} alt="โทรศัพท์" />
              </div>
              <div className="pl-3 pt-2">โทรศัพท์ : +66 278 3888</div>
            </div>
            <div className="pt-6">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d968.7865004060477!2d100.48842638667345!3d13.770067594331113!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29977705f9533%3A0xe592bb9ca7e48636!2zMjk1IOC4i-C4reC4oiDguK3guKPguLjguJPguK3guLHguKHguKPguLTguJnguJfguKPguYwg4LmB4LiC4Lin4LiH4Lia4Liy4LiH4Lii4Li14LmI4LiC4Lix4LiZIOC5gOC4guC4leC4muC4suC4h-C4nuC4peC4seC4lCDguIHguKPguLjguIfguYDguJfguJ7guKHguKvguLLguJnguITguKMgMTA3MDA!5e0!3m2!1sth!2sth!4v1683598548768!5m2!1sth!2sth"
                width="300"
                height="300"
                title="test"
                loading="lazy"
              ></iframe>
            </div>
          </div>
          <div className="flex-1 px-3" style={{ paddingLeft: "10px" }}>
            <div className="pt-6">
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="inputText"
                placeholder="ชื่อ-นามสกุล"
              />
            </div>
            <div className="pt-2">
              <input
                type="text"
                value={emailSend}
                onChange={(e) => setEmail(e.target.value)}
                className="inputText"
                placeholder="อีเมล์"
              />
            </div>
            <div className="pt-2">
              <input
                type="text"
                value={telephone}
                onChange={(e) => setTel(e.target.value)}
                className="inputText"
                placeholder="เบอร์โทรศัพท์"
              />
            </div>
            <div className="pt-2">
              <select
                value={topic}
                onChange={(e) => setTopic(e.target.topic)}
                className="inputText"
              >
                <option value="ปัญหาการใช้งาน">ปัญหาการใช้งาน</option>
                <option value="คำถามเพิ่มเติม">คำถามเพิ่มเติม</option>
                <option value="ปัญหาอื่นๆ">ปัญหาอื่นๆ</option>
              </select>
            </div>
            <div className="pt-2">
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="textAreaShow"
                placeholder="ข้อความ"
              />
            </div>
            <div className="flex pt-4 " style={{ width: "400px" }}>
              <div
                className="flex-1 text-center pt-2 cursor-pointer"
                onClick={clearData}
              >
                <u>ล้างข้อมูล</u>
              </div>
              <div className="flex-1 text-center">
                <button type="button" className="btn" onClick={saveInput}>
                  ส่งข้อมูล
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: "50px" }} className="bg-white"></div>
        <Outlet />
        <ToastContainer />
      </div>
    </>
  );
}

export default Contactus;
