import React from "react";
import "./FaqPC.css";
import faqLogo from "./pic/faq.jpg";

import Question from "./Question";

function FaqPC() {
  return (
    <>
      <div className="faqMain">
        <div className="faqText pt-4">FAQ (คำถามที่พบบ่อย)</div>
        <div className="flex justify-center">
          <img src={faqLogo} alt="faq logo" />
        </div>
        <div>
          <Question
            ques="สามารถชื้อสินค้าได้ที่ไหนบ้าง?"
            answer="สามารถสั่งซื้อสินค้าได้ที่ไลน์แอด @bestitem หรือ กรอกข้อมูลให้เจ้าหน้าที่ติดต่อกลับได้ค่ะ"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ราคาสินค้าเท่าไหร่?"
            answer="ราคาสินค้าต่อชิ้นอยู่ที่ 620 บาท"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ค่าส่งสินค้าราคาเท่าไหร่?"
            answer="ค่าส่ง 50 บาทต่อ 1 คำสั่งซ์้อ (สั่งซื้อสินค้าครบ 300 บาท ส่งฟรี)"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ขั้นต่ำในการซื้อสินค้า?"
            answer="ทางเราไม่มีขั้นต่ำในการซื้อสินค้าค่ะ"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ชำระสินค้าไปแล้ว สามารถเปลี่ยนแปลง หรือยกเลิกได้หรือไม่?"
            answer="หากสินค้าของคุณ ยังไม่ถูกจัดส่ง สามารถทำได้ค่ะ โดยให้คุณรีบติดต่อทีมงานของเรามาค่ะ"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="มีบริการชำระสินค้าปลายทางไหม?"
            answer="ทางเราไม่มีบริการเก็บเงินปลายทาง (COD) ค่ะ เมื่อสั่งสินค้าแล้วต้องชำระเงินและแจ้งยอดการชำระให้
          เรีนบร้อย ทางเราจะทำการจัดส่งสินค้า หลังจากได้รับการแจ้งชำระแล้วเท่านั้นค่ะ "
          />
        </div>
        <div className="h-[30px]"></div>
      </div>
    </>
  );
}

export default FaqPC;
