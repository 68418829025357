import React from "react";
import "./MainTablet.css";
import "./Faq.css";

import logo from "./pic/logo.jpg";
import faq from "./pic/faq.jpg";
import Question from "./Question";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Outlet, Link } from "react-router-dom";

function FaqT() {
  return (
    <>
      <div className="bg-white">
        <div className="menuWhite flex">
          <div className="pt-2 pl-2">
            <div class="dropdown">
              <button class="dropbtn">
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "#E5958E", fontSize: "24px" }}
                />
              </button>
              <div class="dropdown-content">
                <Link to="/">
                  <div>หน้าหลัก</div>
                </Link>
                <Link to="/order">
                  <div>การสั่งซื้อสินค้า</div>
                </Link>
                <Link to="/faq">
                  <div>FAQ</div>
                </Link>
                <Link to="/contactus">
                  <div>ติดต่อเรา</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <img src={logo} style={{ height: "40px" }} alt="best item logo" />
          </div>
        </div>
        <div className="h-12">x</div>
        <div className="faqText">FAQ (คำถามที่พบบ่อย)</div>
        <div className="flex justify-center">
          <img src={faq} alt="faq" />
        </div>
        <div>
          <Question
            ques="สามารถชื้อสินค้าได้ที่ไหนบ้าง?"
            answer="สามารถสั่งซื้อสินค้าได้ที่ไลน์แอด @bestitem หรือ กรอกข้อมูลให้เจ้าหน้าที่ติดต่อกลับได้ค่ะ"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ราคาสินค้าเท่าไหร่?"
            answer="ราคาสินค้าต่อชิ้นอยู่ที่ 620 บาท"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ค่าส่งสินค้าราคาเท่าไหร่?"
            answer="ค่าส่ง 50 บาทต่อ 1 คำสั่งซ์้อ (สั่งซื้อสินค้าครบ 300 บาท ส่งฟรี)"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ขั้นต่ำในการซื้อสินค้า?"
            answer="ทางเราไม่มีขั้นต่ำในการซื้อสินค้าค่ะ"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="ชำระสินค้าไปแล้ว สามารถเปลี่ยนแปลง หรือยกเลิกได้หรือไม่?"
            answer="หากสินค้าของคุณ ยังไม่ถูกจัดส่ง สามารถทำได้ค่ะ โดยให้คุณรีบติดต่อทีมงานของเรามาค่ะ"
          />
        </div>
        <div className="h-[30px]"></div>
        <div>
          <Question
            ques="มีบริการชำระสินค้าปลายทางไหม?"
            answer="ทางเราไม่มีบริการเก็บเงินปลายทาง (COD) ค่ะ เมื่อสั่งสินค้าแล้วต้องชำระเงินและแจ้งยอดการชำระให้
          เรีนบร้อย ทางเราจะทำการจัดส่งสินค้า หลังจากได้รับการแจ้งชำระแล้วเท่านั้นค่ะ "
          />
        </div>
        <div className="h-[30px]"></div>
      </div>
      <Outlet />
    </>
  );
}

export default FaqT;
