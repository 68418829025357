import React from "react";
import "../App.css";
import LeftMenu from "./pc/LeftMenu";
import FaqPC from "./pc/FaqPC";
import FaqT from "./tablet/FaqT";

function Faq() {
  const menu = 3;
  return (
    <>
      <div className="hidden lg:block mainPC ">
        <div className="flex ">
          <div className="w-200">
            <LeftMenu menu={menu} />
          </div>
          <div className="flex-1 bg-white">
            <FaqPC />
          </div>
        </div>
      </div>
      <div className="hidden md:block lg:hidden">
        <FaqT />
      </div>
      <div className=" block md:hidden">
        <FaqT />
      </div>
    </>
  );
}

export default Faq;
