import React from "react";
import "./MainTablet.css";
import logo from "./pic/logo.jpg";
import banner from "./pic/privacy.jpg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Outlet, Link } from "react-router-dom";

function PrivacyT() {
  return (
    <>
      <div className="bg-white">
        <div className="menuWhite flex">
          <div className="pt-2 pl-2">
            <div class="dropdown">
              <button class="dropbtn">
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "#E5958E", fontSize: "24px" }}
                />
              </button>
              <div class="dropdown-content">
                <Link to="/">
                  <div>หน้าหลัก</div>
                </Link>
                <Link to="/order">
                  <div>การสั่งซื้อสินค้า</div>
                </Link>
                <Link to="/faq">
                  <div>FAQ</div>
                </Link>
                <Link to="/contactus">
                  <div>ติดต่อเรา</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <img src={logo} style={{ height: "40px" }} alt="best item logo" />
          </div>
        </div>
        <div className="h-12">x</div>
        <div>
          <img src={banner} alt="privacy policy" />
        </div>
        <div className="pt-4 headerText">นโยบายความเป็นส่วนตัว</div>
        <div className="px-4">
          <div className="pt-4">
            <b>ข้อกำหนด และเงื่อนไข (TERM AND CONDITION)</b>
          </div>
          <div>
            ยินดีต้อนรับและขอขอบคุณสำหรับการเยี่ยมชมเว็บไซต์ของเรา
            หน้าเพจนี้ประกอบไปด้วยข้อกำหนด และเงื่อนไขการจำหน่าย และจัดส่งสินค้า
            ซึ่งต่อไปนี้จะถูกเรียกแทนว่า “สินค้า” เนื้อหาที่แสดงอยู่ บนเว็บไซต์
            ของเรา ที่ปรากฏแก่ท่าน “เงื่อนไข”
            โปรดอ่านข้อกำหนดและเงื่อนไขเหล่านี้ก่อนทำการ
            สั่งซื้อสินค้าจากเว็บไซต์ของเรา การที่ท่านสั่งซื้อสินค้าใดๆ ของเรา
            นั้นหมายความว่า ท่านตกลง ที่จะปฏิบัติตาม ขอกำหนดและเงื่อนไขเหล่านี้
            กรุณาอ่านข้อกำหนดและเงื่อนไขอย่างถี่ถ้วน
            เพื่อรับทราบถึงการเปลี่ยนแปลงในข้อกำหนดและเงื่อนไขเหล่านี้
          </div>
          <div className="pt-4">
            <b>การมีสิทธิ์สั่งซื้อ</b>
          </div>
          <div>
            การส่งคำสั่งซื้อสินค้า ท่านจำเป็นต้องมีอายุเกินสิบแปด (18) ปี
            บริบูรณ์ ถ้าท่านอายุต่ำกว่าสิบแปด (18) ปี ควรได้รับความยินยอม
            จากผู้ปกครอง หรือผู้ดูแลของท่านก่อนสังซื้อ
          </div>
          <div className="pt-4">
            <b>ข้อห้าม</b>
          </div>
          <div>
            ท่านต้องไม่นำเว็บไซต์นี้ไปใช้ในทางที่ผิด ท่านต้องไม่กระทำ
            หรือส่งเสริมให้มีการกระทำความ ผิดทางอาญา โอนถ่าย หรือเผยแพร่ไวรัส
            รวมทั้ง แต่ไม่จำกัดเพียงแค่ Trojan horse, worm, logic bomb หรือโพสต์
            เนื้อหาอื่นๆ บนเว็บไซต์ ที่มีลักษณะประสงค์ร้าย เป็นอันตรายทาง
            เทคโนโลยี ละเมิดความลับ เป็นที่น่ารังเกียจ
            หรือสื่อลามกอนาจารในลักษณะใดๆ ลักลอบเข้าสู่ระบบ ด้านใดๆ
            ของการบริการนี้ นำข้อมูลในนี้ไปใช้ในทางทุจริต
            ก่อความรำคาญใจต่อผู้อื่น ละเมิดสิทธิที่ มีเจ้าของของบุคคลอื่น
            ส่งโฆษณาหรือวัสดุส่งเสริมการขายที่ไม่เป็นที่ต้องการ
            หรือพยายามที่จะสร้างผลกระทบต่อการทำงาน
            หรือการทำงานของสิ่งอำนวยความสะดวกทาง คอมพิวเตอร์ใดๆ ของเว็บไซต์นี้
            หรือที่เข้าถึงได้ทางเว็บไซต์นี้ การละเมิดใดๆ ต่อข้อบัญญัตินี้ถือว่า
            เป็นความผิดทางอาญาภายใต้กฎหมายอาชญากรรมทางคอมพิวเตอร์ (Cyber-Crime)
            ค.ศ.2007, พระราชบัญญัติอาชญากรรมทางคอมพิวเตอร์ ค.ศ.2007
            ในกรณีที่การละเมิดดังกล่าว เกิดขึ้น buaradaofficial
            จะรายงานการละเมิดไปยังหน่วยงานที่บังคับใช้กฎหมายที่เกี่ยวข้อง
            และจะมีการดำเนินการทางกฎหมาย
          </div>
          <div className="pt-4">
            <b>ราคาและการชำระเงิน</b>
          </div>
          <div>
            การอธิบายสินค้า รายละเอียด และราคาที่ปรากฏอยู่ในเว็บไซต์นี้ถูกต้อง
            ความผิดพลาดอาจเกิดขึ้นได้ ถ้าเราพบว่าเกิดความผิดพลาดขึ้น
            ในราคาของสินค้าใดๆ ที่ท่านได้สั่งซื้อ เราจะแจ้งท่านเกี่ยวกับเรื่อง
            นี้โดยเร็วที่สุด และยังคงให้ท่านตัดสินใจว่า
            จะยังคงยืนยันคำสั่งซื้อของท่านเช่นเดิม ณ ราคาที่ ถูกต้อง
            หรือยกเลิกคำสั่งซื้อนั้นไป ถ้าเราไม่สามารถติดต่อท่านได้
            เราจะถือว่าคำสั่งซื้อนั้นถูกยกเลิก ถ้าท่านยกเลิกคำสั่งซื้อ
            ก่อนที่เราจะจัดสั่งสินค้าไปให้ท่าน
            ถ้าท่านได้ชำระเงินสำหรับคำสั่งซื้อนั้นแล้ว
            ท่านจะได้รับเงินคืนทั้งหมด
          </div>
          <div className="px-4">
            ก) ราคานั้นได้รวมภาษีมูลค่าเพิ่ม (VAT) เข้าไว้แล้ว
            และอยู่ในสกุลเงินบาทไทย ค่าใช้จ่าย ในการจัดส่ง (ถ้ามี)
            จะมีการคิดเพิ่มเติมจากราคาสินค้า ค่าจัดส่งดังกล่าวจะแสดงไว้
            อย่างชัดเจนและรวมอยู่ใน “ราคารวมสุดท้าย”
          </div>
          <div className="px-4">
            ข) เราไม่มีข้อผูกพันใดๆ ที่จะต้องปฏิบัติตามคำสั่งซื้อของท่าน
            ถ้าราคาที่แสดงไว้ใน เว็บไซต์นี้ไม่ถูกต้อง
            (แม้ว่าหลังจากที่เรายอมรับคำสั่งซื้อของท่านแล้วก็ตาม)
          </div>
          <div className="px-4 pb-4">
            ค)
            ท่านสามารถชำระเงินโดยใช้ช่องทางบริการชำระเงินของเราที่แสดงรายชื่อไว้ในโฮมเพจ
            ของเรา หรือ ใช้เงินสดชำระเงินตอนส่งมอบสินค้าก็ได้ นอกจากนั้น
            ท่านยังสามารถชำระเงินทั้งหมด
            หรือบางส่วนของราคาสินค้าหรือคูปองส่งเสริม การขาย ได้เช่นกัน
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default PrivacyT;
