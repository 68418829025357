import React from "react";
import symbol from "./pic/reviewsymbol.png";
import star from "./pic/reviewstar.png";
function ReviewPC(props) {
  const { topic, name } = props;
  const styleBg = {
    border: "1px solid #FFB902",
    height: " 300px",
    width: "240px",
  };
  return (
    <div style={styleBg} className="p-4 text-center mx-2">
      <div className="flex justify-center pt-10">
        <img src={symbol} alt="รีวิว" />
      </div>
      <div className="pt-4 h-[120px]" style={{ fontSize: "14px" }}>
        {topic}
      </div>
      <div className="flex justify-center">
        <img src={star} alt="รีวิว rating" />
      </div>
      <div>{name}</div>
    </div>
  );
}

export default ReviewPC;
