import React from "react";
import "../App.css";
import LeftMenu from "./pc/LeftMenu";

import PrivacyPC from "./pc/Privacy";
import PrivacyT from "./tablet/PrivacyT";
import PrivacyM from "./mobile/PrivacyM";

function Privacy() {
  return (
    <>
      <div className="hidden lg:block mainPC ">
        <div className="flex ">
          <div className="w-200">
            <LeftMenu />
          </div>
          <div className="flex-1 bg-white">
            <PrivacyPC />
          </div>
        </div>
      </div>
      <div className="hidden md:block lg:hidden">
        <PrivacyT />
      </div>
      <div className=" block md:hidden">
        <PrivacyM />
      </div>
    </>
  );
}

export default Privacy;
