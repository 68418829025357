import React from "react";
import "./MainTablet.css";

import logo from "./pic/logo.jpg";
import topBanner from "./pic/banner.png";
import useItem from "./pic/usewithhair.jpg";
import trick from "./pic/mbanner.jpg";
import trick01 from "./pic/trick01.jpg";
import trick02 from "./pic/trick02.jpg";
import trick03 from "./pic/trick03.jpg";
import benifit from "./pic/benifit.jpg";
import promotion from "./pic/promotion.png";

import ReviewT from "./ReviewT";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

import { Outlet, Link } from "react-router-dom";

function MainTablet() {
  return (
    <>
      <div className="bg-white">
        <div className="menuWhite flex">
          <div className="pt-2 pl-2">
            <div class="dropdown">
              <button class="dropbtn">
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ color: "#E5958E", fontSize: "24px" }}
                />
              </button>
              <div class="dropdown-content">
                <Link to="/">
                  <div>หน้าหลัก</div>
                </Link>
                <Link to="/order">
                  <div>การสั่งซื้อสินค้า</div>
                </Link>
                <Link to="/faq">
                  <div>FAQ</div>
                </Link>
                <Link to="/contactus">
                  <div>ติดต่อเรา</div>
                </Link>
              </div>
            </div>
          </div>
          <div className="pl-2">
            <img src={logo} style={{ height: "40px" }} alt="best item logo" />
          </div>
        </div>
        <div className="h-12">x</div>
        <div className="flex justify-center">
          <img src={topBanner} alt="best item top banner" />
        </div>
        <div className="px-4">
          <div>
            สวัสดีครับเพื่อนๆ วันนี้น้องสล็อต จะเมาป้ายยาสาวๆ ที่รักใน การหนีบผม
            อยากให้ผมตรงสวยก่อนออก ไปทำภาระกิจต่างๆ แต่ว่าถ้าไปที่ร้านก็แพง
            แถมยังอาจเสียเวลารอช่างอีก หรือสาวๆ คนไหนที่อยากจะทำ เอง
            แต่ก็เลือกไม่ได้ว่าจะใช้รุ่น ไหนดี จะทนไหม
            น้องสล็อตเลยมาแนะนำเครื่องหนีบผมที่ร้านทำผมชอบใช้ กันบอกเลยครับว่า
            ทนทานมาก
          </div>
          <div className="flex pt-4">
            <div className="flex-1">
              <img src={useItem} alt="ผู้หญิงใช้ Super V Inter" />
            </div>
            <div className="flex-1">
              <div>
                <b>เครื่องหนีบผม Super V Inter รุ่น Dee SA</b>
              </div>
              <div>
                เป็นเครื่องหนีบผม ที่ร้านและช่างซาลอนทั่วประเทศ
                ต่างให้ความยอมรับและเห็นเป็นเสียง เดียวกันว่า ใช้ดีจริง
                คุณภาพในการใช้งานสูง ทนทาน แข็งแรง กว่าสินค้า แบรนด์ทั่วไปอีก
                ต้องบอกเลยก็ได้ครับว่า เครื่องหนีบผม Super V Inter รุ่น Dee SA
                นั้นใช่ว่าจะทนทาน เพียง
                อย่างเดียวผลลัพธ์ในการใช้งานก็ออกมาดีเป็นอย่าง มาก
                หนีบเพียงครั่งเดียว ก็ให้ผมตรงสวยงามตามรูปทรงที่ คุณต้องการ
                และอีกทั้งยังผมของคุณก็ยังเงา งาม แลดูสุขภาพดีอีกด้วย
              </div>
            </div>
          </div>
          <div className="pt-4">
            ต้องบอกเลยครับว่า เครื่องหนีบผมของเรานั้น ใช้ได้กับทุกสภาพเส้นผม
            ไม่ว่าจะเป็น สุขภาพผมธรรมดา สุขภาพผมแข็งแรง หรือจะเป็นผมที่แตกปลาย
            ก็ใช้ได้ดีเป็นอย่างยิ่ง และอีกทั้งยังมีการวิจัยออกมาด้วย จากองค์การ
            พีจี แฮร์ แคร์ (PG Hair Care) จากประเทศอเมริกา
          </div>
          <div className="pt-4">
            ที่ว่า เครื่องหนีบผม Super V Inter รุ่น Dee SA ไม่ทำร้ายชั้นแกนของ
            เส้นผมผมที่เสียอยู่แล้วก็ไม่เสีย เพิ่ม โดนกำหนดอุณหภูมิที่เหมาะสมกับ
            เส้นผมดังนี้ สำหรับสาวๆ ที่มีเส้นผมธรรมดา ให้ใช้อุณหภูมิที่ 160
            องศา, สาวๆ ที่มีเส้นผมหน้าแข็งแรง ให้ใช้อุณหภูมิที่ 180 องศา และสาวๆ
            ที่มีผมแตกปลาย ผ่านการทำสี การยืดผม หรือผมเส้นเล็ก ให้ใช้อุณหภูมิที่
            140 องศา เพียงแค่เลือกใช้อุณหภูมิให้เหมาะสมกับเส้นผม ของคุณ
            ผมคุณก็จะไม่ถูกทำร้าย และเงางามอีกด้วย
          </div>
          <div className="py-4">
            <img src={trick} alt="วิธีการใช้ VSuper Inter" />
          </div>

          <div className="trickText mt-4">เคล็ด(ไม่)ลับ การจัดแแต่งทรงผม</div>
          <div className="flex pt-4">
            <div className="flex-1">
              <img src={trick01} alt="เทคนิคทำผมตรง" />
            </div>
            <div className="flex-1">
              <img src={trick02} alt="เทคนิคทำผมเป็นลอน" />
            </div>
            <div className="flex-1">
              <img src={trick03} alt="เทคนิคทำผมสวอน" />
            </div>
          </div>
          <div className="pt-4">
            น้องสล็อตบอกเลยได้คำเดียวครับว่า ถ้าสาวๆ ได้เครื่องนี้ไปแล้ว
            สาวหลายท่านจะต้องติดใจเป็นอย่าง แน่นอน ได้ทรงผมตามที่คุณอยากได้
            และไม่ทำร้ายเส้น ผมของคุณ เพียงราคาแค่ 620 บาท เท่านั้นเอง
            บอกเลยได้ครับว่า ราคานี้คุ้มค่าสุดๆ ใช้งานกันไปยาวๆ
            และที่สำคัญสินค้าของเราจัดส่งเร็วมาก สั่งวันนี้ได้ พรุ่งนี้เลย
            โดยผ่านบริษัทขนส่ง บาคาร่า เอ็กเพรส
          </div>
          <div className="flex">
            <div className="flex-1">
              <img src={benifit} alt="ข้อดีของ Super V Inter" />
            </div>
            <div className="flex-1 px-4">
              <div className="flex pt-10">
                <div className="w-[60px]">ข้อดี</div>
                <div className="pt-4 flex-1">
                  <hr
                    style={{
                      backgroundColor: "#FFB902",
                      height: "1px",
                      border: "none",
                    }}
                  />
                </div>
              </div>
              <div className="flex pt-4">
                <span className="circle">1</span>
                <span className="pl-6">
                  หนีบเรียบ ผมสวยอยู่ทนได้ทั้งวัน เครื่องหนีบร้อนไวภายใน 30
                  วินาที
                </span>
              </div>
              <div className="flex pt-4">
                <span className="circle">2</span>
                <span className="pl-6">
                  สะดวกสะบาย ใช้งานง่าย พกพาไปได้ทุกที่
                </span>
              </div>
              <div className="flex pt-4">
                <span className="circle">3</span>
                <span className="pl-6">
                  ทนทาน แข็งแรง ร้านซาลอน ช่างทำผม ใช้ยี่ห้อนี้ทั้งนั้น
                </span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img src={promotion} alt="promotion" />
        </div>
        <div className="reviewTextT px-4 py-2">รีวิวจากลูกค้าของเรา</div>
        <div className="flex flex-nowrap sliderBar">
          <div>
            <ReviewT
              topic="ถูกต้องตามที่สั่ง ราคาถูกกว่าร้านอื่น... ชอบมากค่ะ  สินค้าใช้ดี ใช้ได้จริงๆๆๆ"
              name="คุณ kamonsit"
            />
          </div>
          <div>
            <ReviewT
              topic="ทดลองใช้แล้วหนีบดีร้อนเร็วมีสายยาวมาแพ็กเกจมาก็โอเคมีรอยกันกระแทกมาให้ด้วยถือว่าโอเคเลยค่ะแนะนำร้านนี้ค่ะ"
              name="คุณ amornmas"
            />
          </div>
          <div>
            <ReviewT
              topic="ได้รับสินค้าแล้วค่ะ สินค้ามาในสภาพเรียบร้อย ไม่มีร่องรอยบุบ แพ็คมาแน่นหนาดี"
              name="คุณ netnapha"
            />
          </div>
          <div>
            <ReviewT
              topic="ลองรีดดูแล้วค่ะ ดีน่ะตรงดีค่ะ แต่รอยรอบเครื่องเยอะไปหน่อย โดยรวมแล้วดี ขอแค่รีดแล้วผมไม่ฟูไม่หยิกพอ"
              name="คุณ Manerat"
            />
          </div>
          <div>
            <ReviewT
              topic="ดีครับมีคุณภาพ มีมาตรฐาน มอก. ส่งไว ใช้งานง่าย ฟังชั่นเพียบ เทียบกับราคานี้แล้วไม่มีไหนถูกกว่า แล้วว รักเลย"
              name="คุณ Wassana"
            />
          </div>
          <div>
            <ReviewT
              topic="ร้านค้าส่งสินค้าไวดีค่ะ แม่ค้าน่ารัก ตอบแชทไวเครื่องหนีบผมแบรนด์นี้ใครๆก็รัก เสียบปลั๊ก กดเปิด หนีบแล้วผมอยู่ทรง แลดูสลวย สวยเก๋"
              name="คุณ Warunee"
            />
          </div>
        </div>
        <div className="py-4 text-center">
          <Link to="/privacy">
            <u>นโบบายเว็บไซด์และความเป็นส่วนตัว</u>
          </Link>
        </div>
      </div>
      <Outlet />
    </>
  );
}

export default MainTablet;
