import React from "react";
import mainBanner from "./pic/mainbannerpc.jpg";
import bestWoman from "./pic/bestitemwoman.jpg";
import mbanner from "./pic/mbanner.jpg";
import trick01 from "./pic/trick01.jpg";
import trick02 from "./pic/trick02.jpg";
import trick03 from "./pic/trick03.jpg";
import benifit from "./pic/benifit.jpg";
import proPic from "./pic/promotion.jpg";
import ReviewPC from "./ReviewPC";
import "./MainPC.css";
function MainPC() {
  return (
    <div className="rpanel">
      <div className="pt-4">
        <img
          src={mainBanner}
          alt="best item main banner"
          style={{ width: "95%", margin: "auto" }}
        />
      </div>
      <div className="px-6">
        <div>
          สวัสดีครับเพื่อนๆ วันนี้น้องสล็อต จะเมาป้ายยาสาวๆ ที่รักในการหนีบผม
          อยากให้ผมตรงสวย ก่อนออกไปทำภาระกิจต่างๆ แต่ว่าถ้าไปที่ร้านก็แพง
          แถมยังอาจเสียเวลารอช่างอีก หรือสาวๆ คนไหนที่อยากจะทำเอง
          แต่ก็เลือกไม่ได้ว่าจะใช้รุ่นไหนดี จะทนไหม
          น้องสล็อตเลยมาแนะนำเครื่องหนีบผม ที่ร้านทำผมชอบใช้กัน บอกเลยครับว่า
          ทนทานมาก
        </div>
        <div className="flex pt-4">
          <div className="w-[400px]">
            <img src={bestWoman} alt="woman use best item " />
          </div>
          <div className="flex-1 pl-4">
            <div>
              <b>เครื่องหนีบผม Super V Inter รุ่น Dee SA</b>
            </div>
            <div>
              เป็นเครื่องหนีบผม ที่ร้านและช่างซาลอนทั่วประเทศ
              ต่างให้ความยอมรับและเห็นเป็นเสียง เดียวกันว่า ใช้ดีจริง
              คุณภาพในการใช้งานสูง ทนทาน แข็งแรง กว่าสินค้าแบรนด์ทั่วไปอีก
              ต้องบอกเลยก็ได้ครับว่า เครื่องหนีบผม Super V Inter รุ่น Dee SA
              นั้นใช่ว่าจะทนทาน
              เพียงอย่างเดียวผลลัพธ์ในการใช้งานก็ออกมาดีเป็นอย่าง มาก
              หนีบเพียงครั่งเดียว ก็ให้ผมตรงสวยงามตามรูปทรงที่คุณต้องการ
              และอีกทั้งยังผมของคุณก็ยังเงางาม แลดูสุขภาพดีอีกด้วย
              ต้องบอกเลยครับว่า เครื่องหนีบผมของเรานั้น ใช้ได้กับทุกสภาพเส้นผม
              ไม่ว่าจะเป็น สุขภาพผมธรรมดา สุขภาพผมแข็งแรง หรือจะเป็นผมที่แตกปลาย
              ก็ใช้ได้ดีเป็นอย่างยิ่ง และอีกทั้งยังมีการวิจัยออกมาด้วย
              จากองค์การ พีจี แฮร์ แคร์ (PG Hair Care) จากประเทศอเมริกา
            </div>
          </div>
        </div>
        <div>
          ที่ว่า เครื่องหนีบผม Super V Inter รุ่น Dee SA ไม่ทำร้ายชั้นแกนของ
          เส้นผม ผมที่เสียอยู่แล้วก็ไม่เสียเพิ่ม โดนกำหนดอุณหภูมิที่เหมาะสมกับ
          เส้นผมดังนี้ สำหรับสาวๆ ที่มีเส้นผมธรรมดา ให้ใช้อุณหภูมิที่ 160 องศา,
          สาวๆ ที่มีเส้นผมหน้าแข็งแรง ให้ใช้อุณหภูมิที่ 180 องศา และสาวๆ
          ที่มีผมแตกปลาย ผ่านการทำสี การยืดผม หรือผมเส้นเล็ก ให้ใช้อุณหภูมิที่
          140 องศา เพียงแค่เลือกใช้อุณหภูมิให้เหมาะสมกับเส้นผมของคุณ
          ผมคุณก็จะไม่ถูกทำร้าย และเงางามอีกด้วย
        </div>
        <div className="pt-4 pb-4">
          <img src={mbanner} alt="how to use best item" />
        </div>

        <div className="trickText ">เคล็ด(ไม่)ลับ การจัดแแต่งทรงผม</div>
        <div className="flex pt-4">
          <div className="flex-1">
            <img src={trick01} alt="เทคนิคหนีบทำให้ผมตรง" />
          </div>
          <div className="flex-1">
            {" "}
            <img src={trick02} alt="เทคนิคหนีบทำให้ผมตรง" />
          </div>
          <div className="flex-1">
            {" "}
            <img src={trick03} alt="เทคนิคหนีบทำให้ผมตรง" />
          </div>
        </div>

        <div>
          น้องสล็อตบอกเลยได้คำเดียวครับว่า ถ้าสาวๆ ได้เครื่องนี้ไปแล้ว
          สาวหลายท่านจะต้องติดใจเป็นอย่างแน่นอน ได้ทรงผมตามที่คุณอยากได้
          และไม่ทำร้ายเส้น ผมของคุณ เพียงราคาแค่{" "}
          <span className="priceOrange">620 บาท </span>
          เท่านั้นเอง บอกเลยได้ครับว่า ราคานี้คุ้มค่าสุดๆ ใช้งานกันไปยาวๆ
          และที่สำคัญสินค้าของเราจัดส่งเร็วมาก สั่งวันนี้ได้ พรุ่งนี้เลย
          โดยผ่านบริษัทขนส่ง บาคาร่า เอ็กเพรส
        </div>
        <div className="flex">
          <div className="flex-1 pt-4">
            <img
              src={benifit}
              alt="ข้อดีของการใข้ best item"
              style={{ width: "80%", margin: "auto" }}
            />
          </div>
          <div className="flex-1 px-4">
            <div className="flex pt-20">
              <div className="w-[60px]">ข้อดี</div>
              <div className="pt-4 flex-1">
                <hr
                  style={{
                    backgroundColor: "#FFB902",
                    height: "1px",
                    border: "none",
                  }}
                />
              </div>
            </div>
            <div className="flex pt-4">
              <span className="circle">1</span>
              <span className="pl-6">
                หนีบเรียบ ผมสวยอยู่ทนได้ทั้งวัน เครื่องหนีบร้อนไวภายใน 30 วินาที
              </span>
            </div>
            <div className="flex pt-4">
              <span className="circle">2</span>
              <span className="pl-6">
                สะดวกสะบาย ใช้งานง่าย พกพาไปได้ทุกที่
              </span>
            </div>
            <div className="flex pt-4">
              <span className="circle">3</span>
              <span className="pl-6">
                ทนทาน แข็งแรง ร้านซาลอน ช่างทำผม ใช้ยี่ห้อนี้ทั้งนั้น
              </span>
            </div>
          </div>
        </div>
        <div>
          <img src={proPic} alt="ซื้อ 1 เครื่อง แถมเคราตีน 2 ขวด" />
        </div>
        <div>
          <div className="reviewText">รีวิวจากลูกค้าของเรา</div>
        </div>
        <div className="flex py-3">
          <div className="flex-1">
            <ReviewPC
              topic="ถูกต้องตามที่สั่ง ราคาถูกกว่าร้านอื่น... ชอบมากค่ะ  สินค้าใช้ดี ใช้ได้จริงๆๆๆ"
              name="คุณ kamonsit"
            />
          </div>
          <div className="flex-1">
            <ReviewPC
              topic="ทดลองใช้แล้วหนีบดีร้อนเร็วมีสายยาวมาแพ็กเกจมาก็โอเคมีรอยกันกระแทกมาให้ด้วยถือว่าโอเคเลยค่ะแนะนำร้านนี้ค่ะ"
              name="คุณ amornmas"
            />
          </div>
          <div className="flex-1">
            <ReviewPC
              topic="ได้รับสินค้าแล้วค่ะ สินค้ามาในสภาพเรียบร้อย ไม่มีร่องรอยบุบ แพ็คมาแน่นหนาดี"
              name="คุณ netnapha"
            />
          </div>
        </div>
        <div className="flex pb-3">
          <div className="flex-1">
            <ReviewPC
              topic="ลองรีดดูแล้วค่ะ ดีน่ะตรงดีค่ะ แต่รอยรอบเครื่องเยอะไปหน่อย โดยรวมแล้วดี ขอแค่รีดแล้วผมไม่ฟูไม่หยิกพอ"
              name="คุณ Manerat"
            />
          </div>
          <div className="flex-1">
            <ReviewPC
              topic="ดีครับมีคุณภาพ มีมาตรฐาน มอก. ส่งไว ใช้งานง่าย ฟังชั่นเพียบ เทียบกับราคานี้แล้วไม่มีไหนถูกกว่า แล้วว รักเลย"
              name="คุณ Wassana"
            />
          </div>
          <div className="flex-1">
            <ReviewPC
              topic="ร้านค้าส่งสินค้าไวดีค่ะ แม่ค้าน่ารัก ตอบแชทไวเครื่องหนีบผมแบรนด์นี้ใครๆก็รัก เสียบปลั๊ก กดเปิด หนีบแล้วผมอยู่ทรง แลดูสลวย สวยเก๋"
              name="คุณ Warunee"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MainPC;
