import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./components/MainApp";
import Order from "./components/Order";
import Faq from "./components/Faq";
import Contactus from "./components/Contactus";
import Privacy from "./components/Privacy";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp />} />
        <Route path="/order" element={<Order />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
